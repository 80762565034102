.page-title-box {
    .page-title {
        font-size: 18px;
        margin: 0;
        line-height: 75px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: inherit;
    }
    .page-title-right {
        float: right;
        margin-top: 20px;
    }

    .breadcrumb {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 1.5rem 0;
        margin-bottom: 1rem;
        list-style: none;
        background-color: transparent !important;
    }
}

.topnav-navbar {
    padding: 0;
    margin: 0;
    min-height: 70px;
    position: relative;
    left: 0 !important;
    z-index: 1002;

    .navbar-brand {
        display: flex;
        align-items: center;
        font-size: 1.3em;
    }
}

.text-muted {
    --bs-text-opacity: 1;
    color: #98a6ad !important;
}

.text-semi-bold {
    font-weight: 600;
}

.text-start {
    text-align: left !important;
}

.bg-info-lighten {
    background-color: rgba(68, 186, 220, 0.25) !important;
}

.bg-primary {
    --bs-bg-opacity: 1;
    --bs-primary-rgb: 114, 124, 245;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.small,
small {
    font-size: 0.75rem;
}

.nav-link {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    color: #3688fc;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.arrow-down {
    display: inline-block;
}

a,
button {
    outline: 0 !important;
}

a {
    color: #727cf5;
    text-decoration: none;
    &:link {
        text-decoration: none !important;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 10px 0;
    font-weight: 700;
}

.h6,
h6 {
    font-size: 0.75rem;
}

.h5,
h5 {
    font-size: 0.9375rem;
}

.h4,
h4 {
    font-size: 1.125rem;
}

.font-14 {
    font-size: 14px !important;
}

.font-13 {
    font-size: 13px !important;
}

.float-end {
    float: right !important;
}

.me-2 {
    margin-right: 0.75rem !important;
}

.me-1 {
    margin-right: 0.375rem !important;
}

.topnav {
    .navbar-nav .nav-link {
        font-size: 0.9375rem;
        position: relative;
        padding: 1rem 2.5rem;
    }

    .nav-item.active > a {
        color: #3688fc;
    }

    .topnav-menu {
        margin: 0;
        padding: 0;
    }

    .nav-link {
        &:first-child {
            padding-left: 0;
        }
    }
}
.box-shadow {
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
.card {
    border: none;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    margin-bottom: 24px;

    .header-title {
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        font-size: 0.9rem;
        margin-top: 0;
    }

    .card-body {
        flex: 1 1 auto;
        padding: 1.5rem 1.5rem;
    }

    &.card-h-100 {
        height: calc(100% - 24px);
    }

    &.tilebox-one svg {
        position: absolute;
        right: 1.5rem;
        font-size: 2rem;
        opacity: 0.3;
    }
}
.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    &.nav-tabs {
        &.nav-bordered {
            border-bottom: 2px solid rgba(152, 166, 173, 0.2);

            li a.active {
                border-bottom: 2px solid #3688fc;
            }

            li a {
                border: 0;
                padding: 0.8rem 2rem !important;
                color: #919ca7;
                font-weight: 600;
            }
        }

        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            color: #484e53;
            background-color: #fff;
            border-color: #dee2e6 #dee2e6 #fff;
        }
    }
}

.me-1 {
    margin-right: 0.375rem !important;
}

.simplebar-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;

    .simplebar-height-auto-observer-wrapper {
        -webkit-box-sizing: inherit !important;
        box-sizing: inherit !important;
        height: 100%;
        width: 100%;
        max-width: 1px;
        position: relative;
        float: left;
        max-height: 1px;
        overflow: hidden;
        z-index: -1;
        padding: 0;
        margin: 0;
        pointer-events: none;
        -webkit-box-flex: inherit;
        -ms-flex-positive: inherit;
        flex-grow: inherit;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
    }

    .simplebar-mask {
        direction: inherit;
        overflow: hidden;
        padding: 0;
        right: 0;
        width: auto !important;
        height: auto !important;
        z-index: 0;
    }

    .simplebar-offset {
        direction: inherit !important;
        -webkit-box-sizing: inherit !important;
        box-sizing: inherit !important;
        resize: none !important;
        padding: 0;
        margin: 0;
        -webkit-overflow-scrolling: touch;
    }

    .simplebar-content-wrapper {
        direction: inherit;
        -webkit-box-sizing: border-box !important;
        box-sizing: border-box !important;
        position: relative;
        display: block;
        height: 100%;
        width: auto;
        overflow: auto;
        max-width: 100%;
        max-height: 100%;
        scrollbar-width: none;
    }

    .simplebar-content-wrapper {
        direction: inherit;
        -webkit-box-sizing: border-box !important;
        box-sizing: border-box !important;
        position: relative;
        display: block;
        height: 100%;
        width: auto;
        overflow: auto;
        max-width: 100%;
        max-height: 100%;
    }
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;

    &.badge-danger-lighten {
        color: #fa6767;
        background-color: rgba(250, 103, 103, 0.18);
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    // color: #6c757d;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // background-color: transparent;
    // border: 1px solid transparent;
    padding: 0.45rem 0.9rem;
    font-size: 0.9rem;
    border-radius: 0.15rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

    &.btn-primary {
        color: #fff;
        background-color: #727cf5;
        border-color: #727cf5;
        box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
    }
}

.form-control-light {
    background-color: #f1f3fa !important;
    border-color: #f1f3fa !important;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.45rem 0.9rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #838c96;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.timeline-alt {
    padding: 20px 0;
    position: relative;
    overflow: auto;
    max-height: 750px;

    .timeline-item:before {
        background-color: #f1f3fa;
        bottom: 0;
        content: '';
        left: 9px;
        position: absolute;
        top: 20px;
        width: 2px;
        z-index: 0;
    }
    .timeline-item {
        position: relative;

        .timeline-item-info {
            display: flex;
            flex-direction: column;
        }

        .timeline-icon {
            float: left;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 2px solid transparent;
            // font-size: 5px;
            text-align: center;
            // line-height: 16px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .timeline-item-info {
            margin-left: 30px;
        }
    }
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;

    .btn-close {
        background-color: transparent;
        padding: 0;
        border: none;
        color: #313a46;
    }

    .modal-colored-header {
        color: #fff;
        border-radius: 0;
    }

    .modal-title {
        margin-top: 0;
    }
    .modal-title {
        margin-bottom: 0;
        line-height: 1.5;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}
