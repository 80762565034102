@import '~bootstrap/scss/bootstrap';
@import './plugins/bootstrap';
@import './plugins/theme';

body {
    font-family: 'Roboto', sans-serif;

    --text: #838c96;
    color: var(--text);
    background-color: #f9f9f9;

    *:focus {
        outline: none;
    }
}
