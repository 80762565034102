.datepicker {
    label {
        font-weight: 600;
    }
    .picker {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
